import React from "react"
import Figure from "react-bootstrap/Figure"
import FigureCaption from "react-bootstrap/FigureCaption"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

import "../style/style.css"

import { useIntl, injectIntl } from "gatsby-plugin-intl"

const ContactSquares = () => {
  const intl = useIntl()

  return (
    <article className="contactSquares">
      <section className="square">
        <Figure>
          <FontAwesomeIcon icon={faPhone} />
          <FigureCaption>
            {intl.formatMessage({ id: "contact_page" })}
          </FigureCaption>
        </Figure>
        <p>
          <FontAwesomeIcon icon={faWhatsapp} /> +32 487 22 44 62
        </p>
        <p>
          <FontAwesomeIcon icon={faMobileAlt} /> +32 487 22 44 62
        </p>
      </section>
      <section className="square">
        <Figure>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <FigureCaption>
            {intl.formatMessage({ id: "contactpageAddress" })}
          </FigureCaption>
        </Figure>
        <p>
          {intl.formatMessage({ id: "contactpageCountry" })} - Brussels{" "}
          {/* {intl.formatMessage({ id: "contactpageCity" })} */}
        </p>
        <p>&nbsp;</p>
      </section>
      <section className="square">
        <Figure>
          <FontAwesomeIcon icon={faEnvelope} />
          <FigureCaption>
            {intl.formatMessage({ id: "contactpageEmail" })}
          </FigureCaption>
        </Figure>
        <p>contact(@)batrade.be</p>
        <p>&nbsp;</p>
      </section>
    </article>
  )
}

export default injectIntl(ContactSquares)
