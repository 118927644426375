import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FormComp from "../components/contactPageForm"
import ContactpageSquares from "../components/contactpageSquares"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style.css'

import { injectIntl, useIntl } from "gatsby-plugin-intl"

const ContactPage = () => {
  const intl = useIntl()
  return (
    < Layout >
      <SEO
        title={intl.formatMessage({ id: "contactPage_seo_title" })}
        description={intl.formatMessage({ id: "contactPage_seo_description" })} />
      <FormComp />
      <ContactpageSquares />
    </Layout >

  )
}
export default injectIntl(ContactPage)
